// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clients-clubsoirte-js": () => import("./../../../src/pages/clients/clubsoirte.js" /* webpackChunkName: "component---src-pages-clients-clubsoirte-js" */),
  "component---src-pages-clients-genesis-media-js": () => import("./../../../src/pages/clients/genesis-media.js" /* webpackChunkName: "component---src-pages-clients-genesis-media-js" */),
  "component---src-pages-clients-joinem-js": () => import("./../../../src/pages/clients/joinem.js" /* webpackChunkName: "component---src-pages-clients-joinem-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-clients-munkytees-js": () => import("./../../../src/pages/clients/munkytees.js" /* webpackChunkName: "component---src-pages-clients-munkytees-js" */),
  "component---src-pages-clients-wso-2-js": () => import("./../../../src/pages/clients/wso2.js" /* webpackChunkName: "component---src-pages-clients-wso-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

